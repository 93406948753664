#email-content {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

a{
  text-decoration: none;
} 

#error-page {
  text-align: center;
}

#meta-container{
  border-bottom: 1px solid rgb(235, 235, 235);
}

#meta-data{
  padding: 8px 30px;
}

#meta-data p{
  font-size: 13px;
  line-height: 11px;
}

.maxWidth{
  max-width: 800px;
  margin: 0px auto;
}

#email-content{
 padding: 20px 30px;
}

#banner{
  position: fixed;
  bottom: 0;
  background-color: #1B21F5;
  width: 100%;
  padding: 7px 30px;
  font-size: 13px;
  color: white;
  font-family: 'Inter', sans-serif;
}

#banner a{
  font-weight: 600;
  color: #FB7B53;
}
#banner a:hover{
  color: #ff8c6a;
}