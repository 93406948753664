#root-container{
    background-color: #1B21F5;
}

#landingpage{
    padding: 40px 32px;
}

.header{
    color: white;
    font-weight: 500;
    font-size: 40px;
    text-align: center;
    max-width: 500px;
    margin: 16px 0 40px 0;
}

.content-wrapper{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

#header-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}


#logo{
    margin: 30px 0 0 0;
}

#logo img{
    height: 32px;
}

.primary-btn{
    font-family: 'Inter', sans-serif;
    background: #FB7B53;
    border-radius: 62px;
    font-weight: 500;
    font-size: 18px;
    color: white;
    padding: 10px 32px;
    outline: none;
    border: none;
}

.primary-btn:hover{
    cursor: pointer;
    background-color: #eb6137;
}

.animation{
    margin: 50px 0 8px 0;

}

.body-text{
    color: rgba(255,255,255, 0.6);
    text-align: center;
    line-height: 24px;
    max-width: 600px;
}

.body-text > a{
    color: rgba(255,255,255, 0.6);
    text-decoration: underline;
}

footer{
    margin-top: 40px;
    border-top: 1px solid rgba(255,255,255, 0.15);
    padding: 60px 32px;
}

#footer-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#footer-logo{
    opacity: 0.25;
    height: 32px;
}

.footer-links > a{
    color: rgba(255,255,255, 0.6);
    margin-left: 40px;
}
.footer-links > a:hover{
    text-decoration: underline;
}

.terms{
    color: white;
}

.big-headline{
    font-size: 36px;
}